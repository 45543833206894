import Table from "./components/Table";
import React, { useState, useRef, useEffect } from "react";

const langChangeBTN = document.querySelector("#langChange");

function App() {
  const childCompRef = useRef();
  const [refType, setRefType] = useState("modernizationWorks");

  //Choosing references type (modernization/commissioning/foreign)
  const refHandler = (e) => {
    setRefType(e.target.id);
    childCompRef.current.resetPage();
  };

  //Change language (required for react part)
  const [lang, setLang] = useState("pl");
  let clickState = 0;

  const handleClick = () => {
    clickState++;
    if (oddOrEven(clickState) === "odd") {
      setLang("en");
    } else {
      setLang("pl");
    }

    function oddOrEven(x) {
      return x & 1 ? "odd" : "even";
    }
  };

  useEffect(() => {
    langChangeBTN.addEventListener("click", handleClick);

    return () => {
      langChangeBTN.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="react">
      <div className="references__top-part">
        <div className="titleStyle__1">
          {lang === "pl" ? "REFERENCJE" : "REFERENCES"}
        </div>
        <div className="titleStyle__2">
          {lang === "pl" ? "Poznaj nasze" : "See our"}
          <span className="titleStyle__2--bold">
            {" "}
            {lang === "pl" ? "realizacje" : "references"}
          </span>
          <div className="references__buttons">
            <a
              href="#references"
              className={`button-style ${
                refType === "modernizationWorks"
                  ? "button-style--2 button-style--4"
                  : "button-style--3"
              }`}
              id={`modernizationWorks`}
              onClick={refHandler}
            >
              {lang === "pl" ? "Prace modernizacyjne" : "Modernization works"}
            </a>
            <a
              href="#references"
              className={`button-style ${
                refType === "commissioningWorks"
                  ? "button-style--2 button-style--4"
                  : "button-style--3"
              }`}
              id="commissioningWorks"
              onClick={refHandler}
            >
              {lang === "pl" ? "Prace rozruchowe" : "Commissioning works"}
            </a>
            <a
              href="#references"
              className={`button-style ${
                refType === "foreignWorks"
                  ? "button-style--2 button-style--4"
                  : "button-style--3"
              }`}
              id="foreignWorks"
              onClick={refHandler}
            >
              {lang === "pl" ? "Prace zagraniczne" : "Foreign works"}
            </a>
          </div>
        </div>
        <div className="section-title-style--3"></div>
      </div>
      <Table
        refType={refType}
        lang={lang}
        pageNumber={1}
        ref={childCompRef}
      ></Table>
    </div>
  );
}

export default App;
