import TableItem from "./TableItem";
import { referencesList_pl, referencesList_en } from "./referencesList";
import React, { useState, forwardRef, useImperativeHandle } from "react";

const itemsPerPage = 6;

const Table = forwardRef((props, ref) => {
  const referencesList =
    props.lang === "pl" ? referencesList_pl : referencesList_en;

  const [pageNumber, setPageNumber] = useState(props.pageNumber);

  const pageHandler = (e) => {
    setPageNumber(parseInt(e.target.textContent));
  };

  // Reset page to first page during change of references (modernization/commissioning/foregin)
  useImperativeHandle(ref, () => ({
    resetPage() {
      setPageNumber(1);
    },
  }));

  const referenceObj = referencesList[props.refType];
  const itemsNumber = referencesList[props.refType].length;
  const pageCount = Math.ceil(itemsNumber / itemsPerPage);

  let itemsDisplay = [];
  function displayItems(itemsPerPage, pageNumber, itemsNumber) {
    let itemsPerLastPage = itemsNumber - itemsPerPage * (pageNumber - 1);
    for (let i = 0; i < itemsPerPage && i < itemsPerLastPage; i++) {
      itemsDisplay.push(
        <TableItem
          item={referenceObj[i + (pageNumber - 1) * itemsPerPage]}
          key={i}
        ></TableItem>
      );
    }
  }

  let pagesDisplay = [];
  function displayPages(pageCount) {
    for (let i = 0; i < pageCount; i++) {
      pagesDisplay.push(
        <a
          href="#references"
          key={i}
          className={`button-style button-style--3 button-style--page ${
            i === pageNumber - 1 ? "button-style--4 button-style--2" : " "
          }`}
          onClick={pageHandler}
        >
          {i + 1}
        </a>
      );
    }
  }

  displayItems(itemsPerPage, pageNumber, itemsNumber);
  displayPages(pageCount);

  return (
    <div key={Math.random()} className="table">
      <div className="table-header-container">
        <div className="table-top">
          <div className="table-header-1">Lata</div>
          <div className="table-header-2">Status</div>
          <div className="table-header-3">Inwestor / Obiekt</div>
        </div>

        <div className="table-header-4 table-header-4-hidden">Opis</div>
      </div>
      {itemsDisplay}
      <div className="table-pages">{pagesDisplay}</div>
    </div>
  );
});

export default Table;
