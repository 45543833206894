import Modal from "./modal";
const modalTop = document.querySelector(".modal__top");
const modalWindow = document.querySelector(".modal");
const overlay = document.querySelector(".overlay");
const map = document.querySelector("#map");
const navigation = document.querySelector(".stickyNavigationBar");
const body = document.querySelector("body");

function TableItem(props) {
  // Add info icon for item with description
  function infoBox(desc) {
    if (desc === "yes") {
      return (
        <div className="table-item-4--info">
          <i className="fas fa-info-circle"></i>
        </div>
      );
    }
  }

  // Display modal component
  const infoClickHandler = (e) => {
    if (props.item.description_long === "yes") {
      let modal = new Modal();
      modal.load(props.item.id);
      modalWindow.scrollBy(0, -10000);
      modalWindow.classList.remove("hidden");
      modalTop.classList.remove("hidden");
      overlay.classList.remove("hidden");
      map.classList.add("hidden");
      navigation.classList.add("hidden");
      body.classList.add("overflow-hidden");
    }
  };

  return (
    <div
      className={`table-header-container table-item-container ${
        props.item.status === "Zakończono" || props.item.status === "Completed"
          ? " "
          : "active"
      }
      ${props.item.description_long === "yes" ? "info" : " "}`}
      onClick={infoClickHandler}
    >
      <div className="table-top">
        <div className="table-header-1 table-item-1">{props.item.year}</div>
        <div
          className={`table-header-2 table-item-2 ${
            props.item.status === "Zakończono" ||
            props.item.status === "Completed"
              ? " "
              : "active"
          }`}
        >
          {props.item.status}
        </div>
        <div className="table-header-3 table-item-3">
          {props.item.investor}
          <span className="table-bottom-text">{props.item.site}</span>
        </div>
      </div>
      <div className="table-header-4 table-item-4">
        <div className="table-item-4--main">
          {props.item.description_short}
          <span className="table-bottom-text">{props.item.unit}</span>
        </div>

        {infoBox(props.item.description_long)}
      </div>
    </div>
  );
}

export default TableItem;
