//Loading text from files
// Further work: add spin loading icon
class Modal {
  constructor(fileName) {
    this.modal_content = document.querySelector(".modal__content");
    if (this.modal_content) this.modal_content.innerHTML = " ";
  }

  load(fileName = null) {
    fetch(`/modal_content/${fileName}.json`)
      .then((res) => {
        return res.json();
      })
      .then((content) => {
        this.addHTMLtags(content);
        this.insertContent(content);
      })
      .catch(() => {
        console.error(`Could not load ${fileName}.json`);
      });
  }

  addHTMLtags(content) {
    for (const entry in content.content) {
      let element = entry.split("_");
      if (element[0] === "p") {
        this.modal_content.insertAdjacentHTML(
          "beforeend",
          `<p  data-modal="content.${entry}"></p>`
        );
      }
      if (element[0] === "img") {
        this.modal_content.insertAdjacentHTML(
          "beforeend",
          `<div class="modal__content--img">
              <img src="${content.content[entry]}">
              </div>`
        );
      }
      if (element[0] === "imgDesc") {
        this.modal_content.insertAdjacentHTML(
          "beforeend",
          `<div class="modal__content--imgDesc"> ${content.content[entry]} </div>`
        );
      }
    }
  }

  insertContent(content) {
    let _elements = document.querySelectorAll("[data-modal");

    _elements.forEach((element) => {
      let keys = element.dataset.modal.split(".");
      let text = keys.reduce((obj, i) => obj[i], content);

      if (text) {
        element.innerHTML = text;
      }
    });
  }
}

export default Modal;
